'use strict';

import React from 'react';
import Helmet from 'react-helmet';

import { StickyNavigation } from '@latitude/sticky-navigation';
import {
  BREAKPOINT,
  COLOR,
  FONT_FAMILY,
  ALIGN,
  MARGIN
} from '@latitude/core/utils/constants';
import { FeatureTiles } from '@latitude/feature-tiles';
import Section from '@latitude/section';
import Text from '@latitude/text/Text';
import { Strong } from '@latitude/text';
import { Lframe } from '@latitude/lframe';

import styled from 'styled-components';
import HeroBranded from '@/components/HeroBranded/HeroBranded';
import Layout from '@/components/layout';
import PaymentOptions, {
  PaymentOptionsColumn
} from '@latitude/payment-options';

import { SITE_URL } from '../../utils/constants';
import ContactingLatitude from './_contacting-latitude';
import imageHero from './images/how-to-protect-yourself-background.png';
import imgCertNzLogo from './images/certnz-logo.png';
import imgNcscLogo from './images/ncsc-logo.png';
import imgNetsafeLogo from './images/netsafe-logo.png';
import imgNzPoliceLogo from './images/nzpolice-logo.png';
import PageData from './data/how-to-protect-yourself.json';
import './how-to-protect-yourself.css';

const HowToProtectYourself = props => {
  return (
    <Layout location={props.location} noMetaKeywords title={PageData.title}>
      <main
        className="navigation-spacer"
        css={`
          background-color: ${COLOR.GREY6};
        `}
      >
        <Helmet>
          <link
            rel="canonical"
            href={`${SITE_URL}${props.location.pathname}`}
          />
          <title>{PageData.meta.title}</title>
          <meta name="description" content={PageData.meta.description} />
        </Helmet>

        <Lframe bgImage={imageHero} />
        <HeroBranded
          className="bg-lightest"
          title={PageData.hero.title}
          text={PageData.hero.text}
          breadcrumbs={PageData.hero.breadcrumbs}
          css={`
            .HeroContent {
              padding: 110pt 0 60pt 0;
            }
            @media (min-width: ${BREAKPOINT.MD}) {
              .HeroTitle {
                padding-top: 40px;
              }
              .HeroText {
                padding-bottom: 25px;
              }
            }
          `}
        />

        <StickyNavigation
          items={PageData.stickyNavigation}
          trackId="how-to-protect-yourself-stickynav"
        />

        <Section className="pt-lg-5 pb-lg-5">
          <div
            id={PageData.stickyNavigation[0].anchor}
            css={`
              margin: 0 10%;
              padding: 15pt;
            `}
          >
            <SectionHeading>
              {PageData.stickyNavigation[0].label}
            </SectionHeading>
            <Text
              css={`
                margin: 0 10%;
                padding: 15pt;
                text-align: center;
              `}
            >
              We urge you to remain vigilant to scams. Please note, we will
              never contact our customers requesting their passwords.
            </Text>
            <Text>
              <p>
                <strong>Be on high alert for phishing scams</strong>
                <br />
                Scammers may contact you by SMS, phone, email or post,
                pretending to be from Latitude or Gem.
              </p>
              <p>
                <strong>
                  Verify any communications you receive to ensure they are
                  authentic
                </strong>
                <br />
                Inspect the sender's email address for anomalies and be wary of
                phone numbers originating from unusual geographic locations.
              </p>
              <p>
                <strong>Be careful with links and attachments</strong>
                <br />
                Instead of clicking links within emails or SMS, navigate to our
                website through your browser or visit our app. Do not open
                attachments from unsolicited contact.
              </p>
              <p>
                <strong>
                  Do not respond to SMS or emails from suspicious numbers or
                  email addresses
                </strong>
                <br />
                If you have received a Latitude or Gem branded scam, please
                report them to{' '}
                <a href="mailto:scams@latitudefinancial.com">
                  scams@latitudefinancial.com
                </a>{' '}
                and then delete the message.
              </p>
              <p>
                <strong>Monitor your bank accounts</strong>
                <br />
                If you spot any unusual or suspicious activity, notify your bank
                immediately.
              </p>
              <p>
                <strong>Use strong and unique passwords</strong>
                <br />
                Regularly update your passwords and ensure you are using strong
                passwords. Enable multi-factor authentication where possible.
              </p>
            </Text>
          </div>
        </Section>

        <Section className="security-common-scams">
          <SectionHeading id={PageData.stickyNavigation[1].anchor}>
            {PageData.stickyNavigation[1].label}
          </SectionHeading>
          <SubHeading>
            Familiarise yourself with the most common types of scams to help
            prevent falling victim to them.
          </SubHeading>
          <PaymentOptions
            className="security-common-scams"
            css={`
              max-width: 1110px;
              margin: 0px auto;
            `}
            data={[
              {
                id: 'phishing-scams',
                title: 'Phishing Scams',
                titleHtml: 'Phishing Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Phishing is a technique scammers use to trick you into
                        providing personal information such as usernames and
                        passwords, credit card details or other personal data,
                        or to download malicious software on your device.
                        Scammers may contact you by email, phone call or text
                        message, often pretending to be from a legitimate
                        business.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a phishing scam</Strong>
                        <ul>
                          <li>
                            They have not addressed you by your proper name.
                          </li>
                          <li>
                            Spelling errors and grammatical mistakes in the
                            message.
                          </li>
                          <li>
                            Similar but slightly incorrect email addresses,
                            logos and URLs.
                          </li>
                          <li>Requests for your one-time password.</li>
                          <li>
                            Use of threatening language and the use of time
                            pressure to convince you to act.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Before clicking on a link, hover your mouse over it
                            to see the actual web address.
                          </li>
                          <li>
                            Contact the organisation through an independent
                            search to verify the legitimacy of the contact.
                          </li>
                          <li>Never give out your one-time passwords.</li>
                          <li>
                            Don&apos;t open an attachment if you weren&apos;t
                            expecting it.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'online-shopping-scams',
                title: 'Online Shopping Scams',
                titleHtml: 'Online Shopping Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Scammers pretend to be legitimate online sellers, either
                        with a fake website or a fake ad on a genuine retailer
                        site, to deceive people into buying from them. Once they
                        have received payment for the product, the shopper might
                        receive the item, but they&apos;ll be fake, or they may
                        not receive anything at all.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of an online shopping scam:</Strong>
                        <ul>
                          <li>
                            Price, benefits or features of the product sound too
                            good to be true.
                          </li>
                          <li>Requesting non-secure payment methods.</li>
                          <li>
                            Information about privacy, terms and conditions or
                            the contact details are inadequate.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of offers that seem too good to be true.
                          </li>
                          <li>
                            Avoid feeling pressured to transfer money to the
                            other party.
                          </li>
                          <li>
                            Check the reviews of the merchant, online store and
                            its products.
                          </li>
                          <li>
                            Be cautious of missing privacy policies, terms and
                            conditions or refund information.
                          </li>
                          <li>
                            Look for &apos;https&apos; and a closed padlock icon
                            in the web browser when making payments online.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'investment-scams',
                title: 'Investment Scams',
                titleHtml: 'Investment Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Scammers may contact you via phone or email offering you
                        an investment opportunity promising big payouts, quick
                        money or guaranteed returns.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of an investment scam:</Strong>
                        <ul>
                          <li>Promises of high returns with low risks.</li>
                          <li>
                            Unsolicited contact offering advice on investments.
                          </li>
                          <li>Requesting non-secure payment methods.</li>
                          <li>
                            Information about privacy, terms and conditions or
                            the contact details are inadequate.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of offers that seem too good to be true and
                            are time-sensitive.
                          </li>
                          <li>
                            Do your own research and get professional advice.
                          </li>
                          <li>
                            Don&apos;t provide your details or respond to
                            unsolicited contact from people you don&apos;t know.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'romance-scams',
                title: 'Romance Scams',
                titleHtml: 'Romance Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Romance scammers use fake online profiles and
                        declarations of love to create a false sense of trust to
                        gain access to your funds.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a romance scam:</Strong>
                        <ul>
                          <li>
                            Declarations of strong feelings towards you just
                            after a few contacts.
                          </li>
                          <li>
                            Their online profile is not consistent with what
                            they tell you.
                          </li>
                          <li>Messages are usually poorly written.</li>
                          <li>
                            Requests for money, gifts or your financial details.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>Research your potential partner online.</li>
                          <li>
                            Conduct a reverse-image search (such as Google or
                            TinEye) to see if the photo has been posted
                            elsewhere on the internet.
                          </li>
                          <li>
                            Be cautious when sharing pictures or videos,
                            especially when you haven&apos;t met them.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              },
              {
                id: 'job-scams',
                title: 'Job Scams',
                titleHtml: 'Job Scams',
                content: (
                  <>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        Jobs and employment scams trick you into handing over
                        your money by offering you a &apos;guaranteed&apos; way
                        to make fast money or to obtain a high-paying job for
                        little effort. Scammers may contact you by email, phone
                        call or text message.
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-col">
                      <Text>
                        <Strong>Signs of a job scam</Strong>
                        <ul>
                          <li>
                            Offers of high paying jobs that require low effort.
                          </li>
                          <li>
                            Demands for an upfront fee to secure a guaranteed
                            job or large returns.
                          </li>
                          <li>
                            Requests of money to be transferred on behalf of
                            someone else.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                    <PaymentOptionsColumn className="security-common-scams-tip">
                      <Text>
                        <Strong>Tips:</Strong>
                        <ul>
                          <li>
                            Be wary of job offers from people or companies you
                            don&apos;t know.
                          </li>
                          <li>Research the company offering the job.</li>
                          <li>
                            Be cautious of bogus employment ads on legitimate
                            job sites.
                          </li>
                        </ul>
                      </Text>
                    </PaymentOptionsColumn>
                  </>
                )
              }
            ]}
            tabContentMinHeight="385px"
          />
        </Section>

        <IndustryPartnersSection
          id={PageData.stickyNavigation[2].anchor}
          title={PageData.stickyNavigation[2].label}
        />

        <ContactingLatitude
          anchor={PageData.stickyNavigation[3].anchor}
          heading={PageData.stickyNavigation[3].label}
        />
      </main>
    </Layout>
  );
};

export default HowToProtectYourself;

const SectionHeading = styled.h2`
  margin: 40px 0;
  color: #000;
  text-align: center;
  font-size: 24px;
  font-family: ${FONT_FAMILY.TITLE};
  line-height: 32px;
  font-weight: 600;
  @media (min-width: ${BREAKPOINT.LG}) {
    margin-top: 72px;
    font-size: 32px;
    line-height: 40px;
  }
`;

const SubHeading = styled(Text)`
  text-align: ${ALIGN.CENTER};
  color: ${COLOR.GREY75};
  margin: 0 auto ${MARGIN.M24};
  @media (min-width: ${BREAKPOINT.SM}) {
    margin-bottom: ${MARGIN.M32};
  }
`;

const IndustryPartnersSection = ({ id, title }) => {
  const tileCtaLabel = 'Find Out More';

  const data = [
    {
      icon: imgCertNzLogo,
      content:
        "CERT NZ is your first port of call when you need to report a cyber security problem. Cert NZ support businesses, organisations and individuals affected by cyber security incidents, and provide trusted and authoritative information and advice.<p>You can report an issue by completing an <a href='https://www.cert.govt.nz/individuals/report-an-issue/' target='_blank'>online form</a>.</p>",
      ctaHref: 'https://www.cert.govt.nz/',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-certnz'
    },
    {
      icon: imgNcscLogo,
      content:
        "The National Cyber Security Centre (NCSC) is part of the Government Communications Security Bureau. Their role is to help New Zealand's most significant public and private sector organisations to protect their information systems from advanced cyber-borne threats.",
      ctaHref: 'http://www.ncsc.govt.nz/',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-ncsc'
    },
    {
      icon: imgNetsafeLogo,
      content:
        "Netsafe is New Zealand's independent, non-profit online safety organisation that provides online safety support, expertise and education to people in New Zealand. You will find simple, easy to understand advice on how to protect yourself online, as well as up-to-date information on the latest online threats and how to respond.<p>You can report a scam to Netsafe using their <a href='https://www.netsafe.org.nz/reportanincident/' target='_blank'>incident form</a>.</p>",
      ctaHref: 'https://www.netsafe.org.nz/',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-netsafe'
    },
    {
      icon: imgNzPoliceLogo,
      content:
        "New Zealand Police provide guidelines to keep yourself safe online and where to get help when things don't go to plan.",
      ctaHref:
        'https://www.police.govt.nz/advice-services/cybercrime-and-internet',
      ctaLabel: tileCtaLabel,
      ctaTrackId: 'security-protect-yourself-nzpolice'
    }
  ];

  return (
    <Section className="security-industry-partners">
      <div
        css={`
          max-width: 1024px;
          margin: 0 auto;
        `}
      >
        <FeatureTiles
          id={id}
          className="security-industry-partner"
          heading={title}
          tiles={data}
          noBackgroundStyles
          useLocalAssets
          tileColumns={2}
        />
      </div>
    </Section>
  );
};
